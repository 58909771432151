import axios from "axios";
import Vue from 'vue';
import {GlobalEventEmitter} from './GlobalEventEmitter.js';
import getEnv from './env.js';
axios.defaults.withCredentials = true

const sha512 = require('sha512')

const baseUrl = (getEnv("BASE_URI") != undefined ? getEnv("BASE_URI") : 'https://tourial.it.wised.it') + '/api/tourial/v1';

async function call(method, uri, payload, silent = false, contentType = 'application/json', isBlob = false){
    
    var result;
    try {
        if(method.toLowerCase() === 'get'){
            result = await axios.get(baseUrl + uri, {
                headers: {
                    'Content-Type': 'application/urlencoded'
                },
                params: payload,
                responseType: isBlob ? 'blob' : undefined
            })
        } else {
            result = await axios[method.toLowerCase()](baseUrl + uri, payload, {
                headers: {
                    'Content-Type': contentType
                },
        })
        }
        
        return result;
    
    } catch (error) {
        error.response.original_status = error.response.status;

        if(error.response.status === 401){
            if(!silent){
                Vue.prototype.$vs.notification({
                    color: 'danger',
                    position: 'top-right',
                    title: 'Qualcosa è andato storto!',
                    text: 'Sessione scaduta. Effettua nuovamente il login.'
                })
                GlobalEventEmitter.$emit('loadUserMeta'); // IMPORTANT: It must be in the silent if, otherwise when App.vue calls getUserMeta() it will be called infinitely
            }
            
            error.response.status = 0;
        } else if(error.response.status === 402){
            if(!silent){
                GlobalEventEmitter.$emit('showPaymentRequired');
            }
            error.response.status = 0;
        } else if(error.response.status === 403){
            if(!silent){
                Vue.prototype.$vs.notification({
                    color: 'danger',
                    position: 'top-right',
                    title: 'Qualcosa è andato storto!',
                    text: 'Non hai i permessi per effettuare questa operazione.'
                })
            }
            error.response.status = 0;
        } else if(error.response.status >= 500 && error.response.status <= 599 && error.response.status != 508){
            if(!silent){
                Vue.prototype.$vs.notification({
                    color: 'danger',
                    position: 'top-right',
                    title: 'Qualcosa è andato storto!',
                    text: 'Errore interno del server. Riprova più tardi.'
                })
            }
            error.response.status = 0;
        }
        return error.response;
    }
    
}


function h(s){
    var h = sha512(s);
    return h.toString('hex');
}
export const apiCall = call;
export const hash = h;
export const baseUri = baseUrl;